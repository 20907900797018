import { languageType } from './util/languageType';

const traverse = require('traverse');
const marketplaceData = [
  {
    label: 'Women|Γυναικεία',
    value: 'women',
    show: true,
    subCategories: {
      filterConfig: {
        indexForSearch: true,
        filterType: 'SelectSingleFilter',
        label: 'Category|Κατηγορία',
        group: 'primary',
      },
      key: 'category',
      scope: 'public',
      schemaType: 'enum',
      enumOptions: [
        {
          label: 'Bags|Τσάντες',
          value: 'bags',
          show: true,
          products: {
            filterConfig: {
              indexForSearch: true,
              filterType: 'SelectSingleFilter',
              label: 'Subcategory|Υποκατηγορία',
              group: 'primary',
            },
            key: 'subCategory',
            scope: 'public',
            schemaType: 'enum',
            enumOptions: [
              {
                label: 'Handbags|Τσάντες χειρός',
                value: 'handbags',
                title: 'Handbag',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },

              {
                label: 'Clutch bags|Τσάντες clutch',
                value: 'clutchbags',
                title: 'Clutch Bag',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Backpacks|Σακίδια',
                value: 'backpacks',
                title: 'Backpack',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Travelbags|Τσάντες Ταξιδιού',
                value: 'travelbags',
                title: 'Travelbag',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: false },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
            ],
          },
        },
        {
          label: 'Clothing|Ρούχα',
          value: 'clothing',
          show: true,
          products: {
            filterConfig: {
              indexForSearch: true,
              filterType: 'SelectSingleFilter',
              label: 'Subcategory|Υποκατηγορία',
              group: 'primary',
            },
            key: 'subCategory',
            scope: 'public',
            schemaType: 'enum',
            enumOptions: [
              {
                label: 'Coats|Παλτό',
                value: 'coats',
                title: 'Coat',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Jackets|Μπουφάν & Σακάκια',
                value: 'jackets',
                title: 'Jacket',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Dresses|Φορέματα',
                value: 'dresses',
                title: 'Dress',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Knitwear|Πλεκτά',
                value: 'knitwear',
                title: 'Knitwear',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Tops|Μπλούζες',
                value: 'tops',
                title: 'Top',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Skirts|Φούστες',
                value: 'skirts',
                title: 'Skirt',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Shorts|Σόρτς',
                value: 'shorts',
                title: 'Shorts',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Swimwear|Μαγιό',
                value: 'swimwear',
                title: 'Swimwear',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Trousers|Παντελόνια',
                value: 'trousers',
                title: 'Trousers',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Leggings|Κολάν',
                value: 'leggings',
                title: 'Leggings',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Jumpsuits|Ολόσωμες φόρμες',
                value: 'jumpsuits',
                title: 'Jumpsuit',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
              {
                label: 'Sports Bras|Αθλητικά Σουτιέν',
                value: 'sportsBras',
                title: 'Sports Bra',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Hervé Léger', value: 'herveLeger', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'EU 32', value: 'eu32', show: true },
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 44', value: 'eu44', show: true },
                    { label: 'EU 46', value: 'eu46', show: true },
                    { label: 'EU 48', value: 'eu48', show: true },
                    { label: 'FR 30', value: 'fr30', show: true },
                    { label: 'FR 32', value: 'fr32', show: true },
                    { label: 'FR 34', value: 'fr34', show: true },
                    { label: 'FR 36', value: 'fr36', show: true },
                    { label: 'FR 38', value: 'fr38', show: true },
                    { label: 'FR 40', value: 'fr40', show: true },
                    { label: 'FR 42', value: 'fr42', show: true },
                    { label: 'FR 44', value: 'fr44', show: true },
                    { label: 'FR 46', value: 'fr46', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                    { label: 'IT 44', value: 'it44', show: true },
                    { label: 'IT 46', value: 'it46', show: true },
                    { label: 'IT 48', value: 'it48', show: true },
                    { label: 'IT 50', value: 'it50', show: true },
                    { label: 'IT 52', value: 'it52', show: true },
                    { label: 'IT 54', value: 'it54', show: true },
                    { label: 'UK 4', value: 'uk4', show: true },
                    { label: 'UK 6', value: 'uk6', show: true },
                    { label: 'UK 8', value: 'uk8', show: true },
                    { label: 'UK 10', value: 'uk10', show: true },
                    { label: 'UK 12', value: 'uk12', show: true },
                    { label: 'UK 14', value: 'uk14', show: true },
                    { label: 'UK 16', value: 'uk16', show: true },
                    { label: 'UK 18', value: 'uk18', show: true },
                    { label: 'UK 20', value: 'uk20', show: true },
                    { label: 'UK 22', value: 'uk22', show: true },
                    { label: 'US 0', value: 'us0', show: true },
                    { label: 'US 2', value: 'us2', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'US 14', value: 'us14', show: true },
                    { label: 'US 16', value: 'us16', show: true },
                  ],
                },
              },
            ],
          },
        },
        {
          label: 'Shoes|Παπούτσια',
          value: 'shoes',
          show: true,
          products: {
            filterConfig: {
              indexForSearch: true,
              filterType: 'SelectSingleFilter',
              label: 'Subcategory|Υποκατηγορία',
              group: 'primary',
            },
            key: 'subCategory',
            scope: 'public',
            schemaType: 'enum',
            enumOptions: [
              {
                label: 'Heels|Τακούνια',
                value: 'heels',
                title: 'Heels',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
              {
                label: 'Flats',
                value: 'flats',
                title: 'Flats',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
              {
                label: 'Sneakers|Αθλητικά',
                value: 'sneakers',
                title: 'Sneakers',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
              {
                label: 'Boots|Μπότες & Μποτάκια',
                value: 'boots',
                title: 'Boots',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
              {
                label: 'Sandals|Σανδάλια',
                value: 'sandals',
                title: 'Sandals',

                show: false,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
              {
                label: 'Mules & Clogs',
                value: 'mulesClogs',
                title: 'Mule & Clog',

                show: false,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: true },
                    { label: 'Adidas', value: 'adidas', show: true },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: true },
                    { label: 'All Saints', value: 'allSaints', show: true },
                    { label: 'American Vintage', value: 'americanVintage', show: true },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
                    { label: 'Anna Riska', value: 'annaRiska', show: true },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: true },
                    { label: 'Armani', value: 'armani', show: true },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: true },
                    { label: 'Balmain', value: 'balmain', show: true },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: true },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: true },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: true },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: true },
                    { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: true },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: true },
                    { label: 'Jacquemus', value: 'jacquemus', show: true },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: true },
                    { label: 'Lanvin', value: 'lanvin', show: true },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: true },
                    { label: 'Max Mara', value: 'maxMara', show: true },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: true },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: true },
                    { label: 'Moschino', value: 'moschino', show: true },
                    { label: 'MSGM', value: 'msgm', show: true },
                    { label: 'Nike', value: 'nike', show: true },
                    { label: 'Pinko', value: 'pinko', show: true },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: true },
                    { label: 'See by Chloé', value: 'seeByChloé', show: true },
                    { label: 'Self Portrait', value: 'selfPortrait', show: true },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: true },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: true },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'EU 34', value: 'eu34', show: true },
                    { label: 'EU 34.5', value: 'eu345', show: true },
                    { label: 'EU 35', value: 'eu35', show: true },
                    { label: 'EU 35.5', value: 'eu355', show: true },
                    { label: 'EU 36', value: 'eu36', show: true },
                    { label: 'EU 36.5', value: 'eu365', show: true },
                    { label: 'EU 37', value: 'eu37', show: true },
                    { label: 'EU 37.5', value: 'eu375', show: true },
                    { label: 'EU 38', value: 'eu38', show: true },
                    { label: 'EU 38.5', value: 'eu385', show: true },
                    { label: 'EU 39', value: 'eu39', show: true },
                    { label: 'EU 39.5', value: 'eu395', show: true },
                    { label: 'EU 40', value: 'eu40', show: true },
                    { label: 'EU 40.5', value: 'eu405', show: true },
                    { label: 'EU 41', value: 'eu41', show: true },
                    { label: 'EU 41.5', value: 'eu415', show: true },
                    { label: 'EU 42', value: 'eu42', show: true },
                    { label: 'EU 42.5', value: 'eu425', show: true },
                    { label: 'EU 43', value: 'eu43', show: true },
                    { label: 'US 3', value: 'us3', show: true },
                    { label: 'US 3.5', value: 'us35', show: true },
                    { label: 'US 4', value: 'us4', show: true },
                    { label: 'US 4.5', value: 'us45', show: true },
                    { label: 'US 5', value: 'us5', show: true },
                    { label: 'US 5.5', value: 'us55', show: true },
                    { label: 'US 6', value: 'us6', show: true },
                    { label: 'US 6.5', value: 'us65', show: true },
                    { label: 'US 7', value: 'us7', show: true },
                    { label: 'US 7.5', value: 'us75', show: true },
                    { label: 'US 8', value: 'us8', show: true },
                    { label: 'US 8.5', value: 'us85', show: true },
                    { label: 'US 9', value: 'us9', show: true },
                    { label: 'US 9.5', value: 'us95', show: true },
                    { label: 'US 10', value: 'us10', show: true },
                    { label: 'US 10.5', value: 'us105', show: true },
                    { label: 'US 11', value: 'us11', show: true },
                    { label: 'US 11.5', value: 'us115', show: true },
                    { label: 'US 12', value: 'us12', show: true },
                    { label: 'IT 33', value: 'it33', show: true },
                    { label: 'IT 33.5', value: 'it335', show: true },
                    { label: 'IT 34', value: 'it34', show: true },
                    { label: 'IT 34.5', value: 'it345', show: true },
                    { label: 'IT 35', value: 'it35', show: true },
                    { label: 'IT 35.5', value: 'it355', show: true },
                    { label: 'IT 36', value: 'it36', show: true },
                    { label: 'IT 36.5', value: 'it365', show: true },
                    { label: 'IT 37', value: 'it37', show: true },
                    { label: 'IT 37.5', value: 'it375', show: true },
                    { label: 'IT 38', value: 'it38', show: true },
                    { label: 'IT 38.5', value: 'it385', show: true },
                    { label: 'IT 39', value: 'it39', show: true },
                    { label: 'IT 39.5', value: 'it395', show: true },
                    { label: 'IT 40', value: 'it40', show: true },
                    { label: 'IT 40.5', value: 'it405', show: true },
                    { label: 'IT 41', value: 'it41', show: true },
                    { label: 'IT 41.5', value: 'it415', show: true },
                    { label: 'IT 42', value: 'it42', show: true },
                  ],
                },
              },
            ],
          },
        },
        {
          label: 'Accessories|Αξεσουάρ',
          value: 'accessories',
          show: true,
          products: {
            filterConfig: {
              indexForSearch: true,
              filterType: 'SelectSingleFilter',
              label: 'Subcategory|Υποκατηγορία',
              group: 'primary',
            },
            key: 'subCategory',
            scope: 'public',
            schemaType: 'enum',
            enumOptions: [
              {
                label: 'Sunglasses|Γυαλιά Ηλίου',
                value: 'sunglasses',
                title: 'Sunglasses',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: false },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Hair Accessories|Αξεσουάρ Μαλλιών',
                value: 'hair',
                title: 'hair',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: false },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: false },
                    { label: 'Celine', value: 'celine', show: false },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: false },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Bag Accessories|Αξεσουάρ Τσάντας',
                value: 'bag',
                title: 'bag',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: false },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Agenda Covers|Ατζέντες',
                value: 'agenda',
                title: 'agenda',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: false },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: false },
                    { label: 'Celine', value: 'celine', show: false },
                    { label: 'Chanel', value: 'chanel', show: false },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: false },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: false },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: false },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: false },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: false },
                  ],
                },
              },
              {
                label: 'Wallets|Πορτοφόλια',
                value: 'wallets',
                title: 'Wallet',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: true },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: true },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: true },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
                    { label: 'Kate Spade', value: 'kateSpade', show: true },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: true },
                    { label: 'Michael Kors', value: 'michaelKors', show: true },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: true },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: true },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Belts|Ζώνες',
                value: 'belts',
                title: 'Belt',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: '70 cm', value: '70Cm', show: true },
                    { label: '75 cm', value: '75Cm', show: true },
                    { label: '80 cm', value: '80Cm', show: true },
                    { label: '85 cm', value: '85Cm', show: true },
                    { label: '90 cm', value: '90Cm', show: true },
                    { label: '95 cm', value: '95Cm', show: true },
                  ],
                },
              },
              {
                label: 'Hats & Beanies|Καπέλα & Σκουφάκια',
                value: 'hats',
                title: 'Hat',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: false },
                    { label: 'Bulgari', value: 'bulgari', show: false },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: false },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: false },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: false },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: true },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: false },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: false },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
                sizes: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectMultipleFilter',
                    label: 'Size|Μέγεθος',
                    group: 'primary',
                  },
                  key: 'size',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'One Size', value: 'oneSize', show: true },
                    { label: 'XXS', value: 'xxs', show: true },
                    { label: 'XS', value: 'xs', show: true },
                    { label: 'S', value: 's', show: true },
                    { label: 'M', value: 'm', show: true },
                    { label: 'L', value: 'l', show: true },
                    { label: 'XL', value: 'xl', show: true },
                    { label: 'XXL', value: 'xxl', show: true },
                    { label: 'XXXL', value: 'xxxl', show: true },
                    { label: 'UK - US 6¼', value: 'ukUs61_4', show: true },
                    { label: 'UK - US 6¾', value: 'ukUs63_4', show: true },
                    { label: 'UK - US 6⅞', value: 'ukUs67_8', show: true },
                    { label: 'UK - US 7', value: 'ukUs7', show: true },
                    { label: 'UK - US 7⅛', value: 'ukUs71_8', show: true },
                    { label: 'UK - US 7¼', value: 'ukUs71_4', show: true },
                    { label: 'UK - US 7⅜', value: 'ukUs73_8', show: true },
                    { label: 'UK - US 7⅝', value: 'ukUs75_8', show: true },
                    { label: '54 cm', value: '54Cm', show: true },
                    { label: '55 cm', value: '55Cm', show: true },
                    { label: '56 cm', value: '56Cm', show: true },
                    { label: '57 cm', value: '57Cm', show: true },
                    { label: '58 cm', value: '58Cm', show: true },
                    { label: '59 cm', value: '59Cm', show: true },
                    { label: '60 cm', value: '60Cm', show: true },
                    { label: '61 cm', value: '61Cm', show: true },
                  ],
                },
              },
              {
                label: 'Scarves|Μαντήλια & Κασκόλ',
                value: 'scarf',
                title: 'scarf',

                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: true },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: false },
                  ],
                },
              },
            ],
          },
        },
        {
          label: 'Jewelry|Κοσμήματα',
          value: 'jewelry',
          show: true,
          products: {
            filterConfig: {
              indexForSearch: true,
              filterType: 'SelectSingleFilter',
              label: 'Subcategory|Υποκατηγορία',
              group: 'primary',
            },
            key: 'subCategory',
            scope: 'public',
            schemaType: 'enum',
            enumOptions: [
              {
                label: 'Bracelets|Βραχιόλια',
                value: 'bracelet',
                title: 'Bracelet',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: true },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: true },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Earrings|Σκουλαρίκια',
                value: 'earring',
                title: 'Earring',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },

                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: true },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: false },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Necklaces|Κολιέ',
                value: 'necklace',
                title: 'Necklace',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },

                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: true },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: true },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: true },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              // {
              //   label: 'Pendants|Μενταγιόν',
              //   value: 'pendant',
              //   title: 'Pendant',
              //   show: true,
              //   brands: {
              //     filterConfig: {
              //       indexForSearch: true,
              //       filterType: 'SelectSingleFilter',
              //       label: 'Designer|Brand',
              //       group: 'primary',
              //     },
              //     key: 'designer',
              //     scope: 'public',
              //     schemaType: 'enum',
              //     enumOptions: [
              //       { label: 'Acne Studios', value: 'acneStudios', show: true },
              //       { label: 'Adidas', value: 'adidas', show: true },
              //       { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: true },
              //       { label: 'Alexander Wang', value: 'alexanderWang', show: true },
              //       { label: 'All Saints', value: 'allSaints', show: true },
              //       { label: 'American Vintage', value: 'americanVintage', show: true },
              //       { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: true },
              //       { label: 'Anna Riska', value: 'annaRiska', show: true },
              //       { label: 'Armani', value: 'armani', show: true },
              //       { label: 'Balenciaga', value: 'balenciaga', show: true },
              //       { label: 'Balmain', value: 'balmain', show: true },
              //       { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: true },
              //       { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
              //       { label: 'Bulgari', value: 'bulgari', show: true },
              //       { label: 'Burberry', value: 'burberry', show: true },
              //       { label: 'CALLISTA', value: 'callista', show: true },
              //       { label: 'Calvin Klein', value: 'calvinKlein', show: true },
              //       { label: 'Cartier', value: 'cartier', show: true },
              //       { label: 'Celine', value: 'celine', show: true },
              //       { label: 'Chanel', value: 'chanel', show: true },
              //       { label: 'Chloé', value: 'chloé', show: true },
              //       { label: 'Christian Louboutin', value: 'christianLouboutin', show: true },
              //       { label: 'Chopard', value: 'chopard', show: true },
              //       { label: 'Coach', value: 'coach', show: true },
              //       { label: 'Costarellos', value: 'costarellos', show: true },
              //       { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: true },
              //       { label: 'Dior', value: 'dior', show: true },
              //       { label: 'DKNY', value: 'dkny', show: true },
              //       { label: 'Dolce & Gabbana', value: 'dG', show: true }, // Renamed from D&G
              //       { label: 'Dsquared2', value: 'dsquared2', show: true },
              //       { label: 'Emilio Pucci', value: 'emilioPucci', show: true },
              //       { label: 'Fendi', value: 'fendi', show: true },
              //       { label: 'Furla', value: 'furla', show: true },
              //       { label: 'Givenchy', value: 'givenchy', show: true },
              //       { label: 'Goyard', value: 'goyard', show: true },
              //       { label: 'Gucci', value: 'gucci', show: true },
              //       { label: 'Hermès', value: 'hermes', show: true },
              //       { label: 'Isabel Marant', value: 'isabelMarant', show: true },
              //       { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: true },
              //       { label: 'Jimmy Choo', value: 'jimmyChoo', show: true },
              //       { label: 'Kenzo', value: 'kenzo', show: true },
              //       { label: 'Lanvin', value: 'lanvin', show: true },
              //       { label: 'Loewe', value: 'loewe', show: true },
              //       { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
              //       { label: 'MCM', value: 'mcm', show: true },
              //       { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: true }, // Renamed from Marc by Marc Jacobs
              //       { label: 'Marni', value: 'marni', show: true },
              //       { label: 'Max Mara', value: 'maxMara', show: true },
              //       { label: 'Michael Kors', value: 'michaelKors', show: true },
              //       { label: 'Missoni', value: 'missoni', show: true },
              //       { label: 'Miu Miu', value: 'miuMiu', show: true },
              //       { label: 'Moncler', value: 'moncler', show: true },
              //       { label: 'Moschino', value: 'moschino', show: true },
              //       { label: 'MSGM', value: 'msgm', show: true },
              //       { label: 'Nike', value: 'nike', show: true },
              //       { label: 'Pinko', value: 'pinko', show: true },
              //       { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: true },
              //       { label: 'Prada', value: 'prada', show: true },
              //       { label: 'Ralph Lauren', value: 'ralphLauren', show: true },
              //       { label: 'Roberto Cavalli', value: 'robertoCavalli', show: true },
              //       { label: 'Saint Laurent', value: 'saintLaurent', show: true },
              //       { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: true },
              //       { label: 'Sandro', value: 'sandro', show: true },
              //       { label: 'See by Chloé', value: 'seeByChloé', show: true },
              //       { label: 'Self Portrait', value: 'selfPortrait', show: true },
              //       { label: 'Stella McCartney', value: 'stellaMcCartney', show: true },
              //       { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
              //       { label: "TOD'S", value: 'tods', show: true },
              //       { label: 'Valentino Garavani', value: 'valentinoGaravani', show: true },
              //       { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
              //       { label: 'Versace', value: 'versace', show: true },
              //       { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: true },
              //       { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: true },
              //       { label: 'Zeus+Dione', value: 'zeusDione', show: true },
              //       { label: 'Other', value: 'other', show: true },
              //     ],
              //   },
              // },
              {
                label: 'Rings|Δαχτυλίδια',
                value: 'ring',
                title: 'Ring',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: true },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: true },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: true },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: true },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: true },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: false },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: true },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
              {
                label: 'Watches|Ρολόγια',
                value: 'watch',
                title: 'Watch',
                show: true,
                brands: {
                  filterConfig: {
                    indexForSearch: true,
                    filterType: 'SelectSingleFilter',
                    label: 'Designer|Brand',
                    group: 'primary',
                  },
                  key: 'designer',
                  scope: 'public',
                  schemaType: 'enum',
                  enumOptions: [
                    { label: 'Acne Studios', value: 'acneStudios', show: false },
                    { label: 'Adidas', value: 'adidas', show: false },
                    { label: 'Alexander McQueen', value: 'alexanderMcQueen', show: false },
                    { label: 'Alexander Wang', value: 'alexanderWang', show: false },
                    { label: 'All Saints', value: 'allSaints', show: false },
                    { label: 'American Vintage', value: 'americanVintage', show: false },
                    { label: 'Ancient Greek Sandals', value: 'ancientGreekSandals', show: false },
                    { label: 'Anna Riska', value: 'annaRiska', show: false },
                    { label: 'AQUAZZURA', value: 'aquazzura', show: false },
                    { label: 'Armani', value: 'armani', show: false },
                    { label: 'Balenciaga', value: 'balenciaga', show: false },
                    { label: 'Bally', value: 'bally', show: false },
                    { label: 'Balmain', value: 'balmain', show: false },
                    { label: 'Bcbg Max Azria', value: 'bcbgMaxAzria', show: false },
                    { label: 'Bottega Veneta', value: 'bottegaVeneta', show: false },
                    { label: 'Bulgari', value: 'bulgari', show: true },
                    { label: 'Burberry', value: 'burberry', show: false },
                    { label: 'CALLISTA', value: 'callista', show: false },
                    { label: 'Calvin Klein', value: 'calvinKlein', show: false },
                    { label: 'Cartier', value: 'cartier', show: true },
                    { label: 'Celine', value: 'celine', show: false },
                    { label: 'Chanel', value: 'chanel', show: true },
                    { label: 'Chloé', value: 'chloé', show: false },
                    { label: 'Christian Louboutin', value: 'christianLouboutin', show: false },
                    { label: 'Chopard', value: 'chopard', show: false },
                    { label: 'Coach', value: 'coach', show: false },
                    { label: 'Costarellos', value: 'costarellos', show: false },
                    { label: 'Diane Von Furstenberg', value: 'dianeVonFurstenberg', show: false },
                    { label: 'Dior', value: 'dior', show: false },
                    { label: 'DKNY', value: 'dkny', show: false },
                    { label: 'Dolce & Gabbana', value: 'dG', show: false }, // Renamed from D&G
                    { label: 'Dsquared2', value: 'dsquared2', show: false },
                    { label: 'Emilio Pucci', value: 'emilioPucci', show: false },
                    { label: 'Fendi', value: 'fendi', show: false },
                    { label: 'Furla', value: 'furla', show: false },
                    { label: 'Givenchy', value: 'givenchy', show: false },
                    { label: 'Goyard', value: 'goyard', show: false },
                    { label: 'Gucci', value: 'gucci', show: true },
                    { label: 'Hermès', value: 'hermes', show: true },
                    { label: 'Isabel Marant', value: 'isabelMarant', show: false },
                    { label: 'Jacquemus', value: 'jacquemus', show: false },
                    { label: 'Jean Paul Gaultier', value: 'jeanPaulGaultier', show: false },
                    { label: 'Jimmy Choo', value: 'jimmyChoo', show: false },
                    { label: 'Kate Spade', value: 'kateSpade', show: false },
                    { label: 'Kenzo', value: 'kenzo', show: false },
                    { label: 'Lanvin', value: 'lanvin', show: false },
                    { label: 'Loewe', value: 'loewe', show: false },
                    { label: 'Louis Vuitton', value: 'louisVuitton', show: true },
                    { label: 'Marc Jacobs', value: 'marcByMarcJacobs', show: false }, // Renamed from Marc by Marc Jacobs
                    { label: 'Marni', value: 'marni', show: false },
                    { label: 'Max Mara', value: 'maxMara', show: false },
                    { label: 'MCM', value: 'mcm', show: false },
                    { label: 'Michael Kors', value: 'michaelKors', show: false },
                    { label: 'Missoni', value: 'missoni', show: false },
                    { label: 'Miu Miu', value: 'miuMiu', show: false },
                    { label: 'Moncler', value: 'moncler', show: false },
                    { label: 'Moschino', value: 'moschino', show: false },
                    { label: 'MSGM', value: 'msgm', show: false },
                    { label: 'Nike', value: 'nike', show: false },
                    { label: 'Pinko', value: 'pinko', show: false },
                    { label: 'Polo Ralph Lauren', value: 'poloRalphLauren', show: false },
                    { label: 'Prada', value: 'prada', show: false },
                    { label: 'Ralph Lauren', value: 'ralphLauren', show: false },
                    { label: 'Roberto Cavalli', value: 'robertoCavalli', show: false },
                    { label: 'Rolex', value: 'rolex', show: true },
                    { label: 'Saint Laurent', value: 'saintLaurent', show: false },
                    { label: 'Salvatore Ferragamo', value: 'salvatoreFerragamo', show: false },
                    { label: 'Sandro', value: 'sandro', show: false },
                    { label: 'See by Chloé', value: 'seeByChloé', show: false },
                    { label: 'Self Portrait', value: 'selfPortrait', show: false },
                    { label: 'Stella McCartney', value: 'stellaMcCartney', show: false },
                    { label: 'Tiffany & Co', value: 'tiffanyCo', show: true },
                    { label: "TOD'S", value: 'tods', show: false },
                    { label: 'Valentino Garavani', value: 'valentinoGaravani', show: false },
                    { label: 'Van Cleef & Arpels', value: 'vanCleefArpels', show: false },
                    { label: 'Versace', value: 'versace', show: false },
                    { label: 'Yves Saint Laurent', value: 'yvesSaintLaurent', show: false },
                    { label: 'Zadig & Voltaire', value: 'zadigVoltaire', show: false },
                    { label: 'Zeus+Dione', value: 'zeusDione', show: false },
                    { label: 'ZIMMERMANN', value: 'zimmermann', show: false },
                    { label: 'Other|Άλλο brand', value: 'other', show: true },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  // {
  //   label: 'Kids|Παιδικά',
  //   value: 'kids',
  //   show: true,
  //   gender: {
  //     filterConfig: {
  //       indexForSearch: true,
  //       filterType: 'SelectSingleFilter',
  //       label: 'Gender|Φύλο',
  //       group: 'primary',
  //     },
  //     key: 'gender',
  //     scope: 'public',
  //     schemaType: 'enum',
  //     enumOptions: [
  //       {
  //         label: 'Boy|Αγόρι',
  //         value: 'boy',

  //         show: true,
  //         subCategories: {
  //           filterConfig: {
  //             indexForSearch: true,
  //             filterType: 'SelectSingleFilter',
  //             label: 'Category|Κατηγορία',
  //             group: 'primary',
  //           },
  //           key: 'category',
  //           scope: 'public',
  //           schemaType: 'enum',
  //           enumOptions: [
  //             {
  //               label: 'Boy Clothes|Αγορίστικα Ρούχα',
  //               value: 'b_clothes',

  //               show: true,
  //               products: {
  //                 filterConfig: {
  //                   indexForSearch: true,
  //                   filterType: 'SelectSingleFilter',
  //                   label: 'Category|Κατηγορία',
  //                   group: 'primary',
  //                 },
  //                 key: 'subCategory',',
  //                 scope: 'public',
  //                 schemaType: 'enum',
  //                 enumOptions: [
  //                   {
  //                     label: 'Boy Tops',
  //                     value: 'tops',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Addidas',
  //                           value: 'addidas',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',
  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',
  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',
  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',
  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                   {
  //                     label: 'Boy Pants',
  //                     value: 'pants',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Addidas',
  //                           value: 'addidas',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                 ],
  //               },
  //             },
  //             {
  //               label: 'Boy Shoes',
  //               value: 'shoes',

  //               show: true,
  //               products: {
  //                 filterConfig: {
  //                   indexForSearch: true,
  //                   filterType: 'SelectSingleFilter',
  //                   label: 'category',
  //                   group: 'primary',
  //                 },
  //                 key: 'subCategory',',
  //                 scope: 'public',
  //                 schemaType: 'enum',
  //                 enumOptions: [
  //                   {
  //                     label: 'Boy Lofers',
  //                     value: 'lofers',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Puma',
  //                           value: 'puma',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                   {
  //                     label: 'Boy Sneakers',
  //                     value: 'sneakers',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Puma',
  //                           value: 'puma',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',
  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                 ],
  //               },
  //             },
  //           ],
  //         },
  //       },
  //       {
  //         label: 'Girl',
  //         value: 'girl',
  //         show: true,
  //         subCategories: {
  //           filterConfig: {
  //             indexForSearch: true,
  //             filterType: 'SelectSingleFilter',
  //             label: 'Category',
  //             group: 'primary',
  //           },
  //           key: 'category',
  //           scope: 'public',
  //           schemaType: 'enum',
  //           enumOptions: [
  //             {
  //               label: 'Girl Clothes',
  //               value: 'clothes',

  //               show: true,
  //               products: {
  //                 filterConfig: {
  //                   indexForSearch: true,
  //                   filterType: 'SelectSingleFilter',
  //                   label: 'category',
  //                   group: 'primary',
  //                 },
  //                 key: 'subCategory',',
  //                 scope: 'public',
  //                 schemaType: 'enum',
  //                 enumOptions: [
  //                   {
  //                     label: 'Girl Tops',
  //                     value: 'tops',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Addidas',
  //                           value: 'addidas',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                   {
  //                     label: 'Girl Pants',
  //                     value: 'g_pants',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Addidas',
  //                           value: 'addidas',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                 ],
  //               },
  //             },
  //             {
  //               label: 'Girl Shoes',
  //               value: 'shoes',

  //               show: true,
  //               products: {
  //                 filterConfig: {
  //                   indexForSearch: true,
  //                   filterType: 'SelectSingleFilter',
  //                   label: 'category',
  //                   group: 'primary',
  //                 },
  //                 key: 'subCategory',',
  //                 scope: 'public',
  //                 schemaType: 'enum',
  //                 enumOptions: [
  //                   {
  //                     label: 'Girl Lofers',
  //                     value: 'lofers',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Puma',
  //                           value: 'puma',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                   {
  //                     label: 'Girl Sneakers Test',
  //                     value: 'sneakers',

  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Puma',
  //                           value: 'puma',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                   {
  //                     label: 'Girl Sneakers',
  //                     value: 'girl_sneakers',
  //                     show: true,
  //                     brands: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Designer',
  //                         group: 'primary',
  //                       },
  //                       key: 'designer',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Nike',
  //                           value: 'nike',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Puma',
  //                           value: 'puma',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     sizes: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Size',
  //                         group: 'primary',
  //                       },
  //                       key: 'size',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'XS',
  //                           value: 'xs',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'S',
  //                           value: 's',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'M',
  //                           value: 'm',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'L',
  //                           value: 'l',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                     colors: {
  //                       filterConfig: {
  //                         indexForSearch: true,
  //                         filterType: 'SelectSingleFilter',
  //                         label: 'Color',
  //                         group: 'primary',
  //                       },
  //                       key: 'color',
  //                       scope: 'public',
  //                       schemaType: 'enum',
  //                       enumOptions: [
  //                         {
  //                           label: 'Red',
  //                           value: 'red',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Blue',
  //                           value: 'blue',

  //                           show: true,
  //                         },
  //                         {
  //                           label: 'Pink',
  //                           value: 'pink',

  //                           show: true,
  //                         },
  //                       ],
  //                     },
  //                   },
  //                 ],
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     ],
  //   },
  // },
];
const defaultColor = [
  { label: 'Anthracite|Ανθρακί', value: 'anthracite', show: true },
  { label: 'Beige|Μπεζ', value: 'beige', show: true },
  { label: 'Black|Μαύρο', value: 'black', show: true },
  { label: 'Blue|Μπλε', value: 'blue', show: true },
  { label: 'Brown|Καφέ', value: 'brown', show: true },
  { label: 'Burgundy|Μπορντό', value: 'burgundy', show: true },
  { label: 'Camel|Καμηλό', value: 'camel', show: true },
  { label: 'Ecru|Εκρού', value: 'ecru', show: true },
  { label: 'Gold|Χρυσό', value: 'gold', show: true },
  { label: 'Green|Πράσινο', value: 'green', show: true },
  { label: 'Grey|Γκρι', value: 'grey', show: true },
  { label: 'Khaki|Χακί', value: 'khaki', show: true },
  { label: 'Metallic|Μεταλλικό', value: 'metallic', show: true },
  { label: 'Multicolour|Πολύχρωμο', value: 'multicolour', show: true },
  { label: 'Orange|Πορτοκαλί', value: 'orange', show: true },
  { label: 'Pink|Ροζ', value: 'pink', show: true },
  { label: 'Purple|Μωβ', value: 'purple', show: true },
  { label: 'Red|Κόκκινο', value: 'red', show: true },
  { label: 'Silver|Ασημένιο', value: 'silver', show: true },
  { label: 'White|Λευκό', value: 'white', show: true },
  { label: 'Yellow|Κίτρινο', value: 'yellow', show: true },
];
const defaultCondition = [
  {
    label: 'Never worn, with tag|Καινούργιο με καρτελάκι',
    value: 'neverWornWithTag',
    show: true,
  },
  {
    label: 'Never worn|Καινούργιο χωρίς καρτελάκι',
    value: 'neverWorn',
    show: true,
  },
  {
    label: 'Excellent Condition|Εξαιρετική κατάσταση',
    value: 'excellent',
    show: true,
  },
  {
    label: 'Very good condition|Πολύ καλή κατάσταση',
    value: 'vgoodCondition',
    show: true,
  },
  {
    label: 'Good condition|Kαλή κατάσταση',
    value: 'goodCondition',
    show: true,
  },
];
const defaultMaterial = [
  {
    label: 'Cashmere|Κασμίρ',
    value: 'cashmere',
    show: true,
  },
  {
    label: 'Cloth|Ύφασμα',
    value: 'cloth',
    show: true,
  },
  {
    label: 'Cotton|Βαμβάκι',
    value: 'cotton',
    show: true,
  },
  {
    label: 'Cotton - Elastane|Βαμβάκι - Ελαστάνη',
    value: 'cottonElastane',
    show: true,
  },
  {
    label: 'Denim - Jeans|Τζιν',
    value: 'denimJeans',
    show: true,
  },
  {
    label: 'Faux fur|Οικολογική γούνα',
    value: 'fauxFur',
    show: true,
  },
  {
    label: 'Fur|Γούνα',
    value: 'fur',
    show: true,
  },
  {
    label: 'Glitter|Γκλίτερ',
    value: 'glitter',
    show: true,
  },
  {
    label: 'Lace|Δαντέλα',
    value: 'lace',
    show: true,
  },
  {
    label: 'Leather|Δέρμα',
    value: 'leather',
    show: true,
  },
  {
    label: 'Linen|Λινό',
    value: 'linen',
    show: true,
  },
  {
    label: 'Lycra|Λύκρα',
    value: 'lycra',
    show: true,
  },
  {
    label: 'Metal|Μέταλλο',
    value: 'metal',
    show: true,
  },
  {
    label: 'Patent leather|Λουστρίνι',
    value: 'patentLeather',
    show: true,
  },
  {
    label: 'Plastic|Πλαστικό',
    value: 'plastic',
    show: true,
  },
  {
    label: 'Polyamide|Πολυαμίδιο',
    value: 'polyamide',
    show: true,
  },
  {
    label: 'Polyester|Πολυεστέρας',
    value: 'polyester',
    show: true,
  },
  {
    label: 'Rubber|Καουτσούκ',
    value: 'rubber',
    show: true,
  },
  {
    label: 'Silk|Μετάξι',
    value: 'silk',
    show: true,
  },
  {
    label: 'Suede|Σουέτ',
    value: 'suede',
    show: true,
  },
  {
    label: 'Synthetic|Συνθετικό',
    value: 'synthetic',
    show: true,
  },
  {
    label: 'Tweed|Τουίντ',
    value: 'tweed',
    show: true,
  },
  {
    label: 'Vegan leather|Βίγκαν δέρμα',
    value: 'veganLeather',
    show: true,
  },
  {
    label: 'Velvet|Βελούδο',
    value: 'velvet',
    show: true,
  },
  {
    label: 'Vinyl|Βινύλιο',
    value: 'vinyl',
    show: true,
  },
  {
    label: 'Viscose|Βισκόζη',
    value: 'viscose',
    show: true,
  },
  {
    label: 'Wicker|Ψάθα',
    value: 'wicker',
    show: true,
  },
  {
    label: 'Wood|Ξύλο',
    value: 'wood',
    show: true,
  },
  {
    label: 'Wool|Μαλλί',
    value: 'wool',
    show: true,
  },
  {
    label: 'Canvas|Καμβάς',
    value: 'canvas',
    show: true,
  },
  {
    label: 'Gold|Χρυσό',
    value: 'gold',
    show: true,
  },
  {
    label: 'Gold Plated|Επίχρυσο',
    value: 'gold_plated',
    show: true,
  },
  {
    label: 'Silver Plated|Eπιχρυσωμένο Aσήμι',
    value: 'silver_plated',
    show: true,
  },
  {
    label: 'Platinum|Πλατίνα',
    value: 'platinum',
    show: true,
  },
  {
    label: 'Rose Gold|Ροζ Χρυσό',
    value: 'rose_gold',
    show: true,
  },
  {
    label: 'Silver|Ασήμι',
    value: 'silver',
    show: true,
  },

  {
    label: 'Steel|Ατσάλι',
    value: 'steel',
    show: true,
  },
  {
    label: 'White Gold|Λευκόχρυσο',
    value: 'white_gold',
    show: true,
  },
  {
    label: 'Yellow Gold|Κίτρινο Χρυσό',
    value: 'yellow_gold',
    show: true,
  },
];

const configLabels = [
  {
    label: 'Condition|Κατάσταση',
    value: 'condition',
  },
  {
    label: 'Material|Υλικό',
    value: 'material',
  },
  {
    label: 'Colour|Χρώμα',
    value: 'colour',
  },
  {
    label: 'Department|Τμήμα',
    value: 'department',
  },
];

const language = languageType();
traverse(configLabels).forEach(function(_) {
  if (_['label']) {
    var label = _['label'].split('|');
    if (label.length > 1 && language == 'el') {
      _['label'] = label[1];
      _['greek'] = label[1];
      _['english'] = label[0];
    } else {
      _['label'] = label[0];
      _['greek'] = label[1];
      _['english'] = label[0];
    }
  }
});
traverse(defaultMaterial).forEach(function(_) {
  if (_['label']) {
    var label = _['label'].split('|');
    if (label.length > 1 && language == 'el') {
      _['label'] = label[1];
      _['greek'] = label[1];
      _['english'] = label[0];
    } else {
      _['label'] = label[0];
      _['greek'] = label[1];
      _['english'] = label[0];
    }
  }
});
traverse(defaultCondition).forEach(function(_) {
  if (_['label']) {
    var label = _['label'].split('|');
    if (label.length > 1 && language == 'el') {
      _['label'] = label[1];
      _['greek'] = label[1];
      _['english'] = label[0];
    } else {
      _['label'] = label[0];
      _['greek'] = label[1];
      _['english'] = label[0];
    }
  }
});
traverse(defaultColor).forEach(function(_) {
  if (_['label']) {
    var label = _['label'].split('|');
    if (label.length > 1 && language == 'el') {
      _['label'] = label[1];
      _['greek'] = label[1];
      _['english'] = label[0];
    } else {
      _['label'] = label[0];
      _['greek'] = label[1];
      _['english'] = label[0];
    }
  }
});
traverse(marketplaceData).forEach(function(_) {
  if (_['label']) {
    var label = _['label'].split('|');
    if (label.length > 1 && language == 'el') {
      _['label'] = label[1];
      _['greek'] = label[1];
      _['english'] = label[0];
    } else {
      _['label'] = label[0];
      _['greek'] = label[1];
      _['english'] = label[0];
    }
  }

  if (_['title']) {
    var title = _['title'].split('|');
    if (title.length > 1 && language == 'el') {
      _['title'] = title[1];
    } else _['title'] = title[0];
  }
});

export { marketplaceData, defaultColor, defaultCondition, defaultMaterial, configLabels };
