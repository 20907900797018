import Gucci from './megaMenuImages/Gucci.png';
import bags_EL from './megaMenuImages/bags_EL.png';
import bags_EN from './megaMenuImages/bags_EN.png';
import wallets_EL from './megaMenuImages/wallets_EL.png';
import wallets_EN from './megaMenuImages/WALLETS_EN.png';
import image3 from './megaMenuImages/test.jpg';
import gucci_portrait from './megaMenuImages/gucci_portrait.png';
import JEWELRY_EL from './megaMenuImages/JEWELRY_EL.png';
import JEWELRY_EN from './megaMenuImages/JEWELRY_EN.png';
import we_are_pop_nouvelle from './megaMenuImages/we_are_pop_nouvelle.png';
import P from './megaMenuImages/P.png';
const traverse = require('traverse');
import { languageType } from './util/languageType';
import { stubFalse } from 'lodash';

// {
//   main: {
//     label: 'New In',
//     url: '',
//   },
//   column: [
//     {
//       title: '',
//       sub: [],
//     },
//   ],
//   clickable: false,
//   hoverable: false,
// },
const megaMenu = [
  {
    main: {
      label: 'New In|Νέες Αφίξεις',
      url: '/s/all_products?pub_department=women',
    },
    column: [],
    clickable: true,
    hoverable: false,
  },
  {
    main: {
      label: 'Top Brands|Top Brands',
      url: ' ',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'Louis Vuitton|Louis Vuitton',
            url: '/s/all_products?pub_department=women&pub_designer=louisVuitton',
          },
          {
            label: 'Gucci|Gucci',
            url: '/s/all_products?pub_department=women&pub_designer=gucci',
          },
          {
            label: 'Chanel|Chanel',
            url: '/s/all_products?pub_department=women&pub_designer=chanel',
          },
          {
            label: 'Bottega Veneta|Bottega Veneta',
            url: '/s/all_products?pub_department=women&pub_designer=bottegaVeneta',
          },
          {
            label: 'Fendi|Fendi',
            url: '/s/all_products?pub_department=women&pub_designer=fendi',
          },
        ],
      },
    ],

    clickable: false,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: Gucci,
      elImageUrl: Gucci,
      url: '/s/all_products?pub_department=women&pub_designer=gucci',
    },
  },

  {
    main: {
      label: 'Bags|Τσάντες',
      url: '/s?pub_category=bags&pub_department=women',
    },
    column: [
      {
        title: 'Categories|Κατηγορίες',
        sub: [
          {
            label: 'All bags|Όλες οι τσάντες',
            url: '/s?pub_category=bags&pub_department=women',
          },
          {
            label: 'Handbags|Τσάντες Χειρός',
            url: '/s?pub_category=bags&pub_department=women&pub_subCategory=handbags',
          },
          {
            label: 'Clutch bags|Τσάντες Clutch',
            url: '/s?pub_category=bags&pub_department=women&pub_subCategory=clutchbags',
          },
          {
            label: 'Backpacks|Σακίδια',
            url: '/s?pub_category=bags&pub_department=women&pub_subCategory=backpacks',
          },
          {
            label: 'Travelbags|Τσάντες Ταξιδιού',
            url: '/s?pub_category=bags&pub_department=women&pub_subCategory=travelbags',
          },
        ],
      },
      {
        title: 'Top Brands|Top Brands',
        sub: [
          {
            label: 'Louis Vuitton|Louis Vuitton',
            url: '/s?pub_category=bags&pub_department=women&pub_designer=louisVuitton',
          },
          {
            label: 'Gucci|Gucci',
            url: '/s?pub_category=bags&pub_department=women&pub_designer=gucci',
          },
          {
            label: 'Chanel|Chanel',
            url: '/s?pub_category=bags&pub_department=women&pub_designer=chanel',
          },
          {
            label: 'Bottega Veneta|Bottega Veneta',
            url: '/s?pub_category=bags&pub_department=women&pub_designer=bottegaVeneta',
          },
          {
            label: 'Fendi|Fendi',
            url: '/s?pub_category=bags&pub_department=women&pub_designer=fendi',
          },
        ],
      },
    ],

    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: bags_EN,
      elImageUrl: bags_EL,
      url: '/s?price=0%2C1000&pub_category=bags&pub_department=women',
    },
  },

  {
    main: {
      label: 'Accessories|Αξεσουάρ',
      url: '/s?pub_category=accessories&pub_department=women',
    },
    column: [
      {
        title: 'Categories|Κατηγορίες',
        sub: [
          {
            label: 'All accessories|Όλα τα αξεσουάρ',
            url: '/s?pub_category=accessories&pub_department=women',
          },
          {
            label: 'Wallets|Πορτοφόλια',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=wallets',
          },
          {
            label: 'Belts|Ζώνες',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=belts',
          },
          {
            label: 'Scarves|Μαντήλια',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=scarf',
          },
          {
            label: 'Sunglasses|Γυαλιά Hλίου',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=sunglasses',
          },
          {
            label: 'Hats & Beanies|Καπέλα & Σκουφάκια',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=hats',
          },
          {
            label: 'Hair Accessories|Αξεσουάρ Mαλλιών',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=hair',
          },
          {
            label: 'Bag Accessories|Αξεσουάρ Τσάντας',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=bag',
          },
          {
            label: 'Agenda Covers|Ατζέντες',
            url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=agenda',
          },
        ],
      },
      {
        title: 'Top Brands|Top Brands',
        sub: [
          {
            label: 'Louis Vuitton|Louis Vuitton',
            url: '/s?pub_category=accessories&pub_department=women&pub_designer=louisVuitton',
          },
          {
            label: 'Gucci|Gucci',
            url: '/s?pub_category=accessories&pub_department=women&pub_designer=gucci',
          },
          {
            label: 'Hermès|Hermès',
            url: '/s?pub_category=accessories&pub_department=women&pub_designer=hermes',
          },
          {
            label: 'Chanel|Chanel',
            url: '/s?pub_category=accessories&pub_department=women&pub_designer=chanel',
          },
          {
            label: 'Bottega Veneta|Bottega Veneta',
            url: '/s?pub_category=accessories&pub_department=women&pub_designer=bottegaVeneta',
          },
        ],
      },
    ],

    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: wallets_EN,
      elImageUrl: wallets_EL,
      url: '/s?pub_category=accessories&pub_department=women&pub_subCategory=wallets',
    },
  },
  {
    main: {
      label: 'Jewelry|Κοσμήματα',
      url: '/s?pub_category=jewelry&pub_department=women',
    },
    column: [
      {
        title: 'Categories|Κατηγορίες',
        sub: [
          {
            label: 'All jewelry|Όλα τα κοσμήματα',
            url: '/s?pub_category=jewelry&pub_department=women',
          },
          {
            label: 'Bracelets|Βραχιόλια',
            url: '/s?pub_category=jewelry&pub_department=women&pub_subCategory=bracelet',
          },
          {
            label: 'Earrings|Σκουλαρίκια',
            url: '/s?pub_category=jewelry&pub_department=women&pub_subCategory=earring',
          },
          {
            label: 'Necklaces|Κολιέ',
            url: '/s?pub_category=jewelry&pub_department=women&pub_subCategory=necklace',
          },
          {
            label: 'Rings|Δαχτυλίδια',
            url: '/s?pub_category=jewelry&pub_department=women&pub_subCategory=ring',
          },
          {
            label: 'Watches|Ρολόγια',
            url: '/s?pub_category=jewelry&pub_department=women&pub_subCategory=watch',
          },
        ],
      },
      {
        title: 'Top Brands|Top Brands',
        sub: [
          {
            label: 'Cartier|Cartier',
            url: '/s?pub_category=jewelry&pub_department=women&pub_designer=cartier',
          },
          {
            label: 'Bulgari|Bulgari',
            url: '/s?pub_category=jewelry&pub_department=women&pub_designer=bulgari',
          },
          {
            label: 'Tiffany & Co|Tiffany & Co',
            url: '/s?pub_category=jewelry&pub_department=women&pub_designer=tiffanyCo',
          },
          {
            label: 'Chanel|Chanel',
            url: '/s?pub_category=jewelry&pub_department=women&pub_designer=chanel',
          },
        ],
      },
    ],
    clickable: true,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: JEWELRY_EN,
      elImageUrl: JEWELRY_EL,
      url: '/s?pub_category=jewelry&pub_department=women',
    },
  },
  {
    main: {
      label: 'Clothing|Ρούχα',
      url: '/s?pub_category=clothing&pub_department=women',
    },
    column: [],

    clickable: true,
    hoverable: false,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: Gucci,
      elImageUrl: Gucci,
      url: '/s?pub_category=clothing&pub_department=women',
    },
  },
  {
    main: {
      label: 'Shoes|Παπούτσια',
      url: '/s?pub_category=shoes&pub_department=women',
    },
    column: [],

    clickable: true,
    hoverable: false,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: Gucci,
      elImageUrl: Gucci,
      url: '/s?pub_category=shoes&pub_department=women',
    },
  },
  {
    main: {
      label: 'Inside Pop Nouvelle|Σχετικά με το Pop Nouvelle',
      url: ' ',
    },
    column: [
      {
        title: '',
        sub: [
          {
            label: 'About us|Σχετικά με εμάς',
            url: '/p/about',
          },
          {
            label: 'Authenticity and Quality Control|Έλεγχος Αυθεντικότητας & Ποιότητας',
            url: '/p/authentication_service',
          },
          {
            label: 'Sustainability|Βιωσιμότητα',
            url: '/p/sustainability_manifesto',
          },
          {
            label: 'Personal Shopping Service|Personal Shopping',
            url: '/p/personal_shopping',
          },
        ],
      },
    ],

    clickable: false,
    hoverable: true,
    image: {
      height: '300px',
      width: '300px',
      imageUrl: we_are_pop_nouvelle,
      elImageUrl: we_are_pop_nouvelle,
      url: '/p/about',
    },
  },
];

const getMegaMenuData = lang => {
  const megaMenuData = JSON.parse(JSON.stringify(megaMenu));

  traverse(megaMenuData).forEach(function(_) {
    if (_['label']) {
      var label = _['label'].split('|');
      if (label.length > 1 && lang == 'el') {
        _['label'] = label[1];
      } else {
        _['label'] = label[0];
      }
    }

    if (_['title']) {
      var title = _['title'].split('|');
      if (title.length > 1 && lang == 'el') {
        _['title'] = title[1];
      } else {
        _['title'] = title[0];
      }
    }
  });

  return megaMenuData;
};

export { getMegaMenuData };
