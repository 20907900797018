import React, { Component } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import Select, { components } from 'react-select';
import css from './FieldSelectModern.module.css';
import { injectIntl } from 'react-intl';
import { languageType } from '../../util/languageType';
const CONTENT_MAX_LENGTH = 5000;
const { Option } = components;
import { IoSearchSharp } from 'react-icons/io5';

const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span role="img" aria-label="search" style={{ marginLeft: '5px' }}>
        <IoSearchSharp className={css.searchIcon} />
      </span>
      {children}
    </components.Control>
  );
};

class FieldSelectModernComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      disabled,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      intl,

      subHeading,
      onFieldChange,
      custom = false,
      sort = true,
      ...rest
    } = this.props;
    if (label && !id) {
      throw new Error('id required when a label is given');
    }
    const language = languageType();
    const isLanguageEn = language === 'en-US';
    let { options = [] } = this.props;
    if (options?.length > 0 && sort) {
      options = options?.sort((a, b) => {
        const locale = isLanguageEn ? 'en' : 'el'; // Use 'en' for English or 'el' for Greek
        return a?.label?.localeCompare(b?.label, locale, { sensitivity: 'base' });
      });
    }
    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';
    const errorText = customErrorText || error;
    const style = {
      input: base => ({
        ...base,
        boxShadow: 'none',
      }),
    };
    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);
    const fieldMeta = { touched: hasError, error: errorText };
    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};
    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      });
    const maxLength = CONTENT_MAX_LENGTH;

    const inputProps = isTextarea
      ? {
          // className: inputClasses,
          id,
          rows: 1,
          maxLength,
          ...refMaybe,
          ...inputWithoutType,
          ...{ options: options },
          ...rest,
        }
      : isUncontrolled
      ? {
          // className: inputClasses,
          id,
          type,
          defaultValue,
          ...refMaybe,
          ...inputWithoutValue,
          ...{ options: options },
          ...rest,
        }
      : {
          // className: inputClasses,
          id,
          type,
          ...refMaybe,
          ...input,
          ...{ options: options },
          ...rest,
        };
    const { onChange } = inputProps;
    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes} style={disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
        {label ? (
          <label htmlFor={id} className={css.label}>
            {label}
          </label>
        ) : null}
        <p className={css.subHeading}>{subHeading}</p>
        <Select
          {...inputProps}
          // styles={style}
          onChange={e => {
            onChange(e);
            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
          noOptionsMessage={({ inputValue }) =>
            inputValue
              ? intl.formatMessage({ id: 'FieldSelectModern.FieldSelectModern.noOptions' })
              : 'No Options'
          }
          components={id == 'brand' ? { Control: CustomControl } : {}}
        />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}
class FieldSelectModern extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }
  render() {
    return <Field component={FieldSelectModernComponent} {...this.props} />;
  }
}
export default injectIntl(FieldSelectModern);
