import React, { useState, useEffect } from 'react';
import css from './FilterPanel.module.css';
import { RxCross1 } from 'react-icons/rx';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const FilterPanel = props => {
  const { validQueryParams, handleRemoveOneFilter, availableFilters, resetAll } = props;
  const history = useHistory();
  const location = history.location;
  const pathname = location?.pathname;
  const showAll = pathname.includes('all_products');
  const isMegaMenuOpen = !showAll;
  function formatPriceRange(priceRange) {
    const [minPrice, maxPrice] = priceRange.split(','); // Split the string by comma
    const euroSign = '€';
    return `${minPrice}${euroSign} - ${maxPrice}${euroSign}`;
  }
  const [selectFilterKeys, setSelectFilterKeys] = useState({});
  useEffect(() => {
    const keys = Object.keys(validQueryParams);
    setSelectFilterKeys(keys);
  }, [validQueryParams]);

  const intl = useIntl();
  const priceFilter = validQueryParams?.price;

  const removeFilters = isMegaMenuOpen
    ? selectFilterKeys?.length &&
      selectFilterKeys
        ?.filter(i => i != 'pub_department')
        ?.filter(i => i != 'showFilters')
        ?.filter(i => i != 'pub_category')
        ?.filter(k => k !== 'keywords')
        ?.filter(k => k !== 'price')
        ?.filter(k => k !== 'sort')
        ?.filter(k => k !== 'mega')
    : (selectFilterKeys?.length &&
        selectFilterKeys
          ?.filter(i => i != 'pub_department')
          ?.filter(i => i != 'showFilters')
          ?.filter(k => k !== 'keywords')
          ?.filter(k => k !== 'price')
          ?.filter(k => k !== 'sort')
          ?.filter(k => k !== 'mega')) ||
      [];
  return selectFilterKeys?.length ? (
    <div className={css.filterContainer}>
      {priceFilter ? (
        <div className={css.filterValues}>
          <div className={css.filterValue}>{formatPriceRange(priceFilter)}</div>
          <div
            className={css.delete}
            onClick={() => {
              handleRemoveOneFilter('price', priceFilter);
            }}
          >
            <RxCross1 className={css.deleteIcon} />
          </div>
        </div>
      ) : null}
      {removeFilters.map((key, index) => {
        const filterKey = key?.split('_')[1];
        const validFilter = availableFilters?.find(filter => filter.key === filterKey);

        const values = validQueryParams[key];

        const multiValue = values?.split(',') || [];

        return (
          <div key={key} className={css.filter}>
            {multiValue?.length > 1 ? (
              multiValue?.map((value, index) => {
                return (
                  <div className={css.filterValues}>
                    <div key={index + key} className={css.filterValue}>
                      {validFilter?.enumOptions?.find(option => option.value === value)?.label}
                    </div>

                    <div
                      key={values}
                      className={css.delete}
                      onClick={() => {
                        handleRemoveOneFilter(key, value);
                      }}
                    >
                      <RxCross1 className={css.deleteIcon} />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={css.filterValues}>
                <div key={index + key} className={css.filterValue}>
                  {validFilter?.enumOptions?.find(option => option.value === values)?.label}
                </div>

                <div
                  key={values}
                  className={css.delete}
                  onClick={() => {
                    handleRemoveOneFilter(key, values);
                  }}
                >
                  <RxCross1 className={css.deleteIcon} />
                </div>
              </div>
            )}
          </div>
        );
      })}
      {removeFilters?.length ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          {intl.formatMessage({ id: 'FilterPanel.FilterPanel.clear' })}
        </button>
      ) : null}
    </div>
  ) : null;
};

export default FilterPanel;
